<template>
<div>
	<CRow>
		<CCol sm="12" md="12">
        <CCard>
          <CCardHeader>

            <CRow>
            <CCol col="4" class="text-left"><h4>Detail Image</h4></CCol>
            <CCol col="8" class="text-right">              
              <b-button-group>
                 <b-button variant="info" v-on:click="actionShowImg()">Preview</b-button>
                 <b-button variant="primary" v-on:click="actionDownload()">Download</b-button>
                 <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                 <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                 <b-button variant="success" v-on:click="ConfirmBGImage()" v-if="seenBtnUpdate">Update</b-button>
              </b-button-group> 
            </CCol> 
            </CRow>

          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>
            <CForm>
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Name</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-input v-model="bgName" :disabled="!isDisableCN" placeholder="Enter Name"></b-form-input>
                   </b-col>                  
              </b-row><br/>

               <CTextarea
                  label="Description"  
                  v-model="bgDesc" 
                  :disabled="!isDisableDsc" 
                  placeholder="Enter Description"
                  horizontal  rows="3"               
               />

                <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Status</label>
                  </b-col>   
                  <b-col sm="9"> 
                      <b-form-select :disabled="!isDisableSts" v-model="recordStatus" :options="optStatus"></b-form-select>
                   </b-col>                  
              </b-row><br/> 

               <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Preview</label>
                  </b-col>   
                  <b-col sm="9">
                      <b-img @error="onImageLoadFailure($event)" thumbnail fluid :src="'data:image/png;base64,'+ imgBGImage " alt=""></b-img>
                  </b-col>                  
                </b-row><br/>
            </CForm>         
           </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import TrService from '@/api/TransactService.js';

  export default {    
    data() {
      return {  
          imageKey:'',          
          defaultImageKey:'',
          imgBGImage:'',
          imgProps: {  width: 300, height: 200  },  
          seenBtnWarn:true,
          seenBtnCcel:false,
          seenBtnUpdate:false,
          isDisableCN:false,
          isDisableDsc:false,
          isDisableSts:false,
          id : this.$route.params.id,
          imageKey : this.$route.params.key,
          bgName:'', 
          bgDesc:'',
          recordStatus:'',
          optStatus:['ACTIVE','INACTIVE'],
          seen:false,
          msg :'',
          color:''          
        };
    },
    created () {   
     
       this.GetbgImageByID(this.id); 

    },
    methods: {

      actionShowImg(){
         var key      = (this.imageKey !== 'undefined') ? this.imageKey : this.defaultImageKey; 
         var is_exist = (this.imageKey !== 'undefined') ? true : false;         
         if(is_exist){ this.downloadBGImage(key,"true"); }       
      },

      onImageLoadFailure (event) {
          event.target.src = 'img/default.png'     
      },

      actionDownload(){
         var key  = this.imageKey; //console.log(key);
         var temp = key.split("."); var ext  = temp[1];
         this.downloadBGImage(key,"false");
      },

      downloadBGImage: function(key,show) {
        TrService.downloadBGImage(key).then(resp => { 

            if(show==="true"){ 
               this.imgBGImage = resp;  
            } else {
              var ImageBase64 = resp;
              var a = document.createElement("a"); //Create <a>
              a.href = "data:image/png; base64," + ImageBase64; //Image Base64 Goes here
              a.download = key; //File name Here
              a.click(); //Downloaded file
            }

        }, error => {
          this.loading = false;
        });
      },

      actionEdit(){
          this.isDisableCN = true;
          this.isDisableDsc = true;
          this.isDisableSts = true;

          this.seenBtnUpdate = true;
          this.seenBtnCcel = true;
          this.seenBtnWarn = false;
      },

      actionCancel(){
          this.isDisableCN = false;
          this.isDisableDsc = false;
          this.isDisableSts = false;

          this.seenBtnUpdate = false;
          this.seenBtnCcel = false;
          this.seenBtnWarn = true;
      },

      ConfirmBGImage(){          
        let params = {}; var validate = [];
        params['name']   = this.bgName;
        params['description'] = this.bgDesc;    
        params['recordStatus'] = this.recordStatus; 
        params['movementType'] = "P2P";

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){   //console.log(params); 
            //if(localStorage.getItem('sa')==="true"){  
              this.UpdateBGImage(this.id,params); 
            // } else { 
            //   this.msg   = 'Sorry, your role not allowed this action!'; 
            //   this.color = 'warning'; this.seen  = true;
            // }            
        } else {
           this.msg   = 'Data Not Complete, All field required!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);
      }, 
      
      GetbgImageByID: function(id) { 
          TrService.GetbgImageByID(id).then(resp => {   //console.log(resp); 
          
              this.bgName = resp.name;
              this.bgDesc = resp.description;
              this.recordStatus = resp.recordStatus;
              this.imageKey = resp.imagePath;
            
           }, error => {
            console.log('error');    
          });
      },  

      UpdateBGImage: function(id,params) { 
          TrService.UpdateBGImage(id,params).then(resp => {   //console.log(resp);           

              if(resp.message=="Success"){
                this.msg   = 'Update Image Successfully'; 
                this.color = 'success';  this.seen  = true;
                this.actionCancel();
              } else {
                this.msg   = !resp.data.message ? "Update Image Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>